<template>
    <v-dialog width="600" v-model="openSendReportDialog" persistent>
        <v-card class="">
            <v-toolbar tile flat dark class="bg-gradient">
                <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="$emit('close')"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>{{ $t('confirmation') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="mt-6">
                <h5 class="text-center">{{ $t(report.operation.report_status === 'DRAFT' ? 'report_to_send' : 'report_to_confirm') }} :</h5>
                <v-alert border='left' class="mb-0 mt-3" color="info" dark text>
                    <span v-html="$t(report.operation.report_status === 'DRAFT' ? 'send_report_confirmation_reminder' : 'confirm_report_confirmation_reminder')"></span>
                </v-alert>
                <v-row class="mt-2">
                    <v-col cols="12" v-if="missingDatas().length">
                        <h6>{{ $t("missingdatatext") }}</h6>
                        <ul class="black--text">
                            <li v-for="missingData in missingDatas()"><span v-if="missingData.value">{{ missingData.value }} </span>{{ missingData.name }}</li>
                        </ul>
                    </v-col>
                    <v-col cols="12">
                        <span v-if="!missingDatas().length">
                            <v-btn depressed color="success" @click="send" :loading="loader">{{ $t(report.operation.report_status === 'DRAFT' ? 'send' : 'confirm') }}</v-btn>
                        </span>
                        <span v-else>
                            <v-btn class="mr-2" depressed color="lightgrey" @click="send" :loading="loader">{{ $t(report.operation.report_status === 'DRAFT' ? 'send_despite_missing_info' : 'confirm_despite_missing_info') }}</v-btn>
                            <v-btn depressed color="primary" @click="$emit('close')">{{ $t('complete_the_report') }}</v-btn>
                        </span>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'

export default {
    name: "dialogProgramRpComponent",
    props: ['openSendReportDialog', 'report'],
    data() {
        return {
            loader: false
        }
    },

    methods: {
        close() {
            this.$emit('close')
        },
        send() {
            this.loader = true;
            GenericDataService.postData('/operation/' + this.report.operation.id + '/sendReport').then((response) => {
                this.loader = false;
                this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
                this.$emit('refresh');
                this.$store.dispatch("operation/SET_SEND_REPORT_DIALOG", false);
            }).catch((error) => {
                this.loader = false
            });
        },
        missingDatas() {
            let missingDatas = [];

            let missingComments = 0;
            missingComments = this.report.operation.report_comments.customercomment ? missingComments : missingComments + 1;
            missingComments = this.report.operation.report_comments.clientcomment ? missingComments : missingComments + 1;
            if(missingComments)
                missingDatas.push({ name: this.$t(missingComments > 1 ? 'comments' : 'comment').toLowerCase(), value: missingComments });

            let notQualifiedSales = this.report.sales.filter(sale => sale.status !== "FAIL" && sale.status !== "SUCCESS").length;
            if(notQualifiedSales)
                missingDatas.push({ name: this.$t(notQualifiedSales > 1 ? 'demandsnotqualified' : 'demandnotqualified').toLowerCase(), value: notQualifiedSales });

            if(!this.report.medias.length)
                missingDatas.push({ name: this.$t('atleastonemedia') });

            return missingDatas;
        }
    },
}
</script>